import { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import './Styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Orders() {
    const [ordersByLocation, setOrdersByLocation] = useState({});

    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl("https://dispatch.neska.com.sa/orderHub")
            .configureLogging(signalR.LogLevel.Debug)
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000]) // Automatically retry at intervals
            .build();

        const startConnection = async () => {
            try {
                await connection.start();
                console.log("Connected!");
                subscribeToEvents();
            } catch (err) {
                console.error('Error connecting: ', err);
                setTimeout(startConnection, 5000); // Retry connection
            }
        };

        const subscribeToEvents = () => {
            connection.on("ReceiveOrder", order => {
                const newOrder = JSON.parse(order);
                setOrdersByLocation(prev => {
                    const location = newOrder.location;
                    const updatedOrders = { ...prev };
                    if (!updatedOrders[location]) {
                        updatedOrders[location] = [];
                    }
                    // Check if the new order is already included
                    const existingOrderIndex = updatedOrders[location].findIndex(o => o.id === newOrder.id);
                    if (existingOrderIndex === -1) {
                        updatedOrders[location].push(newOrder);
                    } else {
                        // Optionally, update the existing order instead of ignoring it
                        updatedOrders[location][existingOrderIndex] = newOrder;
                    }
                    return updatedOrders;
                });
            });
        };

        startConnection();

        return () => {
            connection.off("ReceiveOrder"); // Properly remove the event listener
            connection.stop().then(() => console.log("Connection stopped")).catch(err => console.error("Error stopping the connection:", err));
        };
    }, []);

    function getOrderType(type) {
        const orderTypes = {
            1: 'Pickup',
            2: 'Delivery',
            3: 'Eat In',
            4: 'Curbside'
        };
        return orderTypes[type] || 'Unknown Order Type';
    }

    const getStatusDescription = (status) => {
        const statusMap = {
            1: 'Pending',
            2: 'Confirmed',
            4: 'Cancelled',
            // Add other statuses as necessary
        };
        return statusMap[status] || 'Unknown Status';
    };

    return (
        <div className="container">
            {Object.entries(ordersByLocation).map(([locationId, orders], locIndex) => (
                <div key={locationId} className="mb-4">
                    <h2 className="mb-3">{getLocationName(locationId)}</h2>
                    {orders.map((order, orderIndex) => (
                        <div key={order.id || `order-${locIndex}-${orderIndex}`} className="card">
                            <div className="card-body">
                                <h3 className="card-title">Order ID: {order.channelOrderDisplayId}</h3>
                                <p className="card-text">Date and Time: {new Date(order._created).toLocaleString()}</p>
                                <p>Order Type: {getOrderType(order.orderType)}</p>
                                <p>Status: {getStatusDescription(order.status)}</p>
                                <p className="card-text">Channel: {getChannelName(order.channelLink)}</p>
                                <ul className="list-group list-group-flush">
                                    {order.items.map((item, itemIndex) => (
                                        <li key={`item-${order.id}-${itemIndex}`} className="list-group-item">
                                            {item.name} - Qty: {item.quantity} at SAR {item.price / 100} each
                                            {item.subItems && item.subItems.length > 0 && (
                                                <ul className="list-group mt-2">
                                                    {item.subItems.map((sub, subIndex) => (
                                                        <li key={`subitem-${order.id}-${itemIndex}-${subIndex}`} className="list-group-item">
                                                            {sub.name} - Qty: {sub.quantity} at SAR {sub.price / 100} each
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                                {order.note && <p>Note: {order.note}</p>}
                            </div>
                        </div>
                    ))}
                    {locIndex !== Object.keys(ordersByLocation).length - 1 && <hr />}
                </div>
            ))}
        </div>
    );
}

function getLocationName(locationId) {
    const locations = {
        '64a7ae568dace3a87fefd58a': 'Bakkar - Sahafa',
        '64a7ac7cb46e58a43a4e3e44': 'KRNSH - Al Rabie',
        '658019d33379dcf64159e376': 'Neska Food Happiness - Al Mohammadiyyah',
        '65801ac25eb153bfeab5513e': 'Neska Food Happiness - Al Rehab - Buraydah',
        '659bd0cb0ceb1f107913ad1b': 'Neska Food Happiness - Narges',
        '65f969a9c78131b1dfcf50d2': 'Rosella - Al Rabie',
        '64c782d9825b501d0713dad7': 'Bakkar - An Naziyah - Buraydah',
        '64c78278c7633684e22c21e8': 'BiGA - An Naziyah - Buraydah',
        '64c7830d109b7a781a33ecad': 'KOSHKA - An Naziyah - Buraydah',
        '64a7afc9469991d430949d5a': 'KOSHKA - Al Rabie',
        '64d1dd5a5b75e13cb9e273b6': 'Bakkar - SahafaMaqsaf Hakim - Al Muruj',
        '64a7ab1ab0ed557e2275edcd': 'BiGA - Al Rabie',
        '64a2b347df994939682cb0d8': 'Bo Kees - Al Mohammadiyyah',
        '64a28ed8371bfa0ef65d47b0': 'Bo Kees - Al Rabie',
        '64a2b347df994939682cb0e6': 'Bo Kees - Buraydah - Rayan'
    };
    return locations[locationId] || 'Unknown location';
}

function getChannelName(channelLinkId) {
    const channelLinks = {
        '64a7aeece27a447fe96d39e2': 'Cari',
        '64a7aea359b08730cdbc8b18': 'HungerStation',
        '64a7aeaf7ecd5d4a2b4ea30f': 'Jahez',
        '64e454810ecb869707bacf0f': 'Mrsool',
        '65e04c1ba7f07cf3d27d0281': 'Ninja',
        '663b24bb30e02166467eec77': 'Noon Food',
        '64a7aebbc1d18d7e71358008': 'The Chefz',
        '64a7aeddb46e58a43a4f43fa': 'Tkyer',
        '64a7aecf752ecc2b7a2edc2b': 'ToYou',

        '64a7abc57ecd5d4a2b4cd3f2': 'Cari',
        '64a7ab886701ed1a608e8c27': 'HungerStation',
        '64a7ab9015dc754effe490db': 'Jahez',
        '64e4537e1bba4ed4db71bc55': 'Mrsool',
        '65f43ad7e0385852495f8224': 'Ninja',
        '663b24dc08b1dd651475b6a3': 'Noon Food',
        '64a7ab9dcf6b2b1723d72b4b': 'The Chefz',
        '64a7abb71f64c2d6af087934': 'Tkyer',
        '64a7abab8dace3a87fee5f09': 'ToYou',

        '64d0b2125525294ade7975db': 'Cari',
        '64a2b347df994939682cb0db': 'HungerStation',
        '64a2b347df994939682cb0da': 'Jahez',
        '64e45442b268b1b54eba38ed': 'Mrsool',
        '65f43af1dcf3b8b5fc390e86': 'Ninja',
        '663b25595e692183d57fe2ea': 'Noon Food',
        '64bfc3af62b39c2293ff9e41': 'The Chefz',
        '64a2b347df994939682cb0e0': 'Tkyer',
        '64a2b347df994939682cb0dc': 'ToYou',

        '64d0b1fcdec86ade967d52fe': 'Cari',
        '64a28f1537c5ca0c8b382b61': 'HungerStation',
        '64a28f05750cbed465141471': 'Jahez',
        '64e4542a6033016e52089b48': 'Mrsool',
        '65f43aec8a3e89f58ee6a4b9': 'Ninja',
        '663b253da10e4df189b62c77': 'Noon Food',
        '64a28f463ca2f5df7e1ca367': 'The Chefz',
        '64a28f520ec8b65b6ae97286': 'Tkyer',
        '64a28f3a3e264ea933b6eaa3': 'ToYou',

        '64a7aeece27a447fe96d39e2': 'Cari',
        '64a2b347df994939682cb0eb': 'HungerStation',
        '64a2b347df994939682cb0e9': 'Jahez',
        '64e454579505eec59c3dfe29': 'Mrsool',
        '65e1baa418b75959a23a717f': 'Ninja',
        '663b24bb30e02166467eec77': 'Noon Food',
        '64a2b348df994939682cb0ed': 'The Chefz',
        '64a2b348df994939682cb0ee': 'Tkyer',
        '66583c9d744a61fafc617fd3': 'ToYou',
        //KRNSH - Al Rabie
        '64a7ad04e27a447fe96c8183': 'Cari',
        '64a7acc80b3a7858c969ffbc': 'HungerStation',
        '64a7acd3598053b07959bdac': 'Jahez',
        '64e453bab3c4fddfedca4bb1': 'Mrsool',
        '65e1b62ae8f3e6ddcb0891df': 'Ninja',
        '66a24c61da35fa16899214b3': 'Noon Food',
        '64a7ace0b46e58a43a4e634d': 'The Chefz',
        '64a7acf73d5898889bbe9b17': 'Tkyer',
        '64a7acedf2d9081932033449': 'ToYou',
        //Neska Food Happiness - Al Mohammadiyyah

        '64a7aefb66ecce169c62f178': 'Cari',
        '64a7abe7f2d908193202d6d6': 'Cari',
        '64a7ad3bb706e064a9ca0b33': 'Cari',
        '65801a8e0913e90ad17d4931': 'Cari',

        '64a7aefb66ecce169c62f174': 'HungerStation',
        '64a7abe7f2d908193202d6d2': 'HungerStation',
        '64a7ad3ab706e064a9ca0b2f': 'HungerStation',
        '658019fd8aa4f466a77354e9': 'HungerStation',

        '64ca0de5d7e9d14d5530b214': 'Jahez',
        '64a7abe7f2d908193202d6d1': 'Jahez',
        '64a7ad3ab706e064a9ca0b2e': 'Jahez',
        '65801a0a74bc9991cc5ae7ca': 'Jahez',

        '64e45498b3c4fddfedcac4e5': 'Mrsool',
        '64e4533856d0ae615ed67cf2': 'Mrsool',
        '64e453ce56d0ae615ed6cfa1': 'Mrsool',
        '65801a16d6efdd43364864ec': 'Mrsool',

        '65e1baa418b75959a23a717f': 'Ninja',
        '663b24bb30e02166467eec77': 'Noon Food',
        '64a2b348df994939682cb0ed': 'The Chefz',
        '64a2b348df994939682cb0ee': 'Tkyer',
        '66583c9d744a61fafc617fd3': 'ToYou',

        '64a7aeece27a447fe96d39e2': 'Cari',
        '64a2b347df994939682cb0eb': 'HungerStation',
        '64a2b347df994939682cb0e9': 'Jahez',
        '64e454579505eec59c3dfe29': 'Mrsool',
        '65e1baa418b75959a23a717f': 'Ninja',
        '663b24bb30e02166467eec77': 'Noon Food',
        '64a2b348df994939682cb0ed': 'The Chefz',
        '64a2b348df994939682cb0ee': 'Tkyer',
        '66583c9d744a61fafc617fd3': 'ToYou'
    };
    return channelLinks[channelLinkId] || 'Unknown Channel';
}

export default Orders;